<template>
  <div>
    <transition
      enter-active-class="animate__animated animate__fadeInUp animate__fast"
      leave-active-class="animate__animated animate__fadeOutDown animate__fast"
    >
      <div
        class="flex flex-col cart-extra z-hard shadow-2xl bg-white subway_sixinch"
        v-if="showExtra"
      >
        <div class="h-full flex flex-col z-hard">
          <button
            class="flex items-center justify-between py-2 lg:py-4 px-4 w-full bg-secondary side-cart-top"
            @click="$emit('closeCard')"
          >
            <span class="text-primary-dark subway_footlong fs-14 text-left">{{$t("You can add the items blow")}}</span>
            <div class="flex aic jcc w-40p h-40p text-primary-dark">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M7 16l-4-4m0 0l4-4m-4 4h18"
                />
              </svg>
            </div>
          </button>
          <div
            v-if="ExtraProducts && ExtraProducts.length"
            class="flex flex-col aic w-full lg:w-4/12 lg:max-w-max add-item rounded-t-2xl lg:rounded-b-2xl"
          >
            <div class="flex flex-col w-full bg-white p-5 rounded-2xl">
              <div class="flex flex-col jcb aic pb-8">
                <div class="px-4 sm:px-6 space-y-4 divide-y-2 divide-dashed divide-gray-100 w-full">
                  <div
                    class="flex aic jcb w-full py-3"
                    v-for="product in ExtraProducts"
                    :key="product.id"
                  >
                    <img
                      :src="
                          product.image
                            ? storageUrl + product.image
                            : '/dish.webp'
                        "
                      :alt="product.name"
                      class="w-80p h-80p object-cover"
                    />
                    <div class="flex flex-col jcc pl-3 space-y-2">
                      <h3 class="fs-13 w-full whitespace-wrap text-primary-dark">
                        {{ product.name }}
                      </h3>
                      <h2 class="fs-14 w-full whitespace-wrap uppercase text-black">
                        {{ product.description }}
                      </h2>
                      <div class="flex aic">
                        <div class="flex aic w-120p">
                          <button
                            class="flex aic jcc w-35p h-35p rounded-full bg-gray"
                            @click="removeFromBasket(product)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M18 12H6"
                              />
                            </svg>
                          </button>
                          <div class="flex aic jcc text-primary text-center fs-14 w-50p">
                            {{ product.quantity }}
                          </div>
                          <button
                            class="flex aic jcc w-35p h-35p rounded-full bg-gray"
                            @click="addToBasket(product)"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              class="h-4 w-4"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                stroke-width="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                    <span class="fs-14 subway_footlong  text-primary-dark ml-auto mt-auto"><span class="font-normal mr-1">{{ currency }}</span>{{ product.price }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition
      enter-active-class="animate__animated animate__fadeIn animate__fast"
      leave-active-class="animate__animated animate__fadeOut animate__fast"
    >
      <div
        class="cart-overlay"
        v-if="showExtra"
        @click="$emit('closeCard')"
      ></div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "CartExtra",
  props: ["showExtra"],

  computed: {
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    ShoppingCart() {
      return this.$store.state.orderiomApiPackage.product.ShoppingCart;
    },
    TotalPrice() {
      return this.$store.state.orderiomApiPackage.product.TotalPrice || "0.00";
    },
    SubtotalPrice() {
      return (
        this.$store.state.orderiomApiPackage.product.SubtotalPrice || "0.00"
      );
    },
    counter() {
      let quantity = this.ShoppingCart.map((m) => {
        return m.quantity;
      });
      let count = quantity.reduce(function (a, b) {
        return a + b;
      }, 0);
      return count;
    },
    shippingPrice() {
      return (
        this.$store.state.orderiomApiPackage.product.shippingPrice || "0.00"
      );
    },
    totalWithDiscount() {
      return this.$store.state.orderiomApiPackage.product.totalWithDiscount;
    },
    ExtraCategories() {
      let categories = [
        ...this.$store.state.orderiomApiPackage.product.categories,
      ];
      let extraList = categories.filter((cat) => {
        return cat.extra == 1;
      });
      return extraList;
    },
    ExtraProducts() {
      return this.loadExtraProdut
        ? this.$store.state.orderiomApiPackage.product.products
        : [];
    },
  },
  methods: {
    addToBasket(product) {
      this.$store.dispatch("order/addToBasket", {
        productId: product.id,
        restaurantId: localStorage.getItem("restaurantId"),
        attributeItems: [],
      });
    },
    removeFromBasket(product) {
      this.$store.dispatch("order/removeFromBasket", {
        productId: product.id,
        restaurantId: localStorage.getItem("restaurantId"),
      });
    },
    goCheckOut() {
      if (this.$route.path != "/cart") this.$router.push("/cart");
      this.$emit("closeCard");
    },
    add(product) {
      this.$store.dispatch("order/addToBasket", {
        productId: product.RestaurantProductId,
        attributeItems: product.items.map((m) => {
          return m.id;
        }),
        restaurantId: this.restaurantId,
      });
    },
    deletFromBasket(product) {
      this.$store.dispatch("order/deleteFromBasket", {
        productId: product.RestaurantProductId,
        restaurantId: localStorage.getItem("restaurantId"),
      });
    },
    remove(product) {
      this.$store.dispatch("order/removeFromBasketWithAttr", {
        basketProductId: product.basketProductId,
        restaurantId: this.restaurantId,
      });
    },
  },
  mounted() {
    // this.$store
    //   .dispatch("product/getCategories", localStorage.getItem("restaurantId"))
    //   .then(() => {
    if (this.ExtraCategories.length) {
      this.$store
        .dispatch("product/getProducts", {
          category: this.ExtraCategories[0],
          restaurantId: localStorage.getItem("restaurantId"),
        })
        .then(() => {
          this.loadExtraProdut = true;
        });
    }
    //   });
  },
};
</script>
