<template>
  <div>
    <div class="relative pt-16 pb-0 lg:py-16 md:pr-20 lg:pr-0 subway_sixinch">
      <div class="relative lg:py-12 w-full xl:w-9/12 mx-auto min-container mt-20">
        <div class="">
          <div class="py-10">
            <div class="flex flex-col px-6 lg:flex-row w-full xl:w-10/12 mx-auto jcb add-item-area">
              <div
                class="w-full "
                :class="{'lg:w-8/12':ExtraProducts && ExtraProducts.length}"
              >
                <div class="flex flex-col pb-5 w-full lg:w-11/12">
                  <div class="flex jcb aic mb-5">
                    <h2 class="fs-14 b ">
                      {{$t("My Cart")}} ( {{ counter }} {{$t("items")}} )
                    </h2>
                  </div>

                  <div class="flex flex-col pt-5">
                    <ul class="list-unstyled md:border-b">
                      <li class="hidden md:flex">
                        <ul class="grid grid-rows-6 grid-cols-10 grid-flow-col gap-1 fs-14 text-gray-600 border-b pb-3 w-full">
                          <li class="row-span-6 col-span-1 flex aic">{{$t("Product")}}</li>
                          <li class="row-span-6 col-span-4 flex aic">&nbsp;</li>
                          <li class="row-span-6 col-span-1 flex aic jcc">
                            {{$t("Price")}}
                          </li>
                          <li class="row-span-6 col-span-3 flex aic jcc">{{$t("QTY")}}</li>
                          <li class="row-span-6 col-span-1 flex aic jcc">
                            {{$t("Total")}}
                          </li>
                        </ul>
                      </li>
                      <li>
                        <ul class="divide-y-2 divide-gray-100 divide-dashed">
                          <li
                            class="py-4 bg-white py-8 lg:py-4 mb-3 lg:mb-0"
                            v-for="product in ShoppingCart"
                            :key="product.id"
                          >
                            <ul class="grid lg:grid-rows-6 grid-cols-10 grid-flow-col gap-1 animate__animated animate__fadeIn">
                              <li class="row-span-6 col-span-2 md:col-span-1 flex md:aic place-self-start">
                                <div class="flex-shrink-0 bg-white p-2 rounded-xl w-full">
                                  <img
                                    :src="
                                    product.image
                                      ? storageUrl + product.image
                                      : '/dish.webp'
                                  "
                                    :alt="product.name"
                                    class="checkout_img object-cover"
                                  />
                                </div>
                              </li>
                              <li class="row-span-3 md:row-span-6 col-span-4 flex aic self-start xs:self-stretch">
                                <div class="flex flex-col pl-2 w-10/12">
                                  <div class="fs-14 text-gray-800 b tracking-wide">
                                    {{ product.name }}
                                  </div>
                                  <div
                                    v-if="product.is_package == 1"
                                    class="flex flex-col text-gray-800 w-full mt-1"
                                  >
                                    <span
                                      class="fs-11 capitalize"
                                      v-for="(item, i) in product.items"
                                      :key="i"
                                    >
                                      {{item.name}}
                                      <!-- <span
                                        class=""
                                        v-if="i < product.items.length - 1"
                                      >,</span> -->
                                    </span>
                                  </div>
                                  <div
                                    v-if="product.is_package !== 1"
                                    class="flex w-full mt-1 flex-col text-gray-800"
                                  >
                                    <span
                                      class="fs-11 capitalize"
                                      v-for="(item, i) in product.items"
                                      :key="i"
                                    >
                                      {{item.item}}
                                      <!-- <span
                                        class="mr-1"
                                        v-if="i < product.items.length - 1"
                                      >,</span> -->
                                    </span>
                                  </div>
                                </div>
                              </li>
                              <li class="row-span-3 md:row-span-6 col-span-4 md:col-span-1 flex aic justify-start md:justify-center pl-2 md:pl-0 self-start xs:self-stretch mt-4 md:mt-0">
                                <div class="fs-13 b text-gray-800 whitespace-nowrap">
                                  {{ currency }} {{ product.price }}
                                </div>
                              </li>
                              <li class="row-span-3 md:row-span-6 col-span-5 md:col-span-3 flex justify-end md:justify-center md:aic md:jcc self-start xs:self-center">
                                <div class="fs-14 text-gray-800 ">
                                  <div class="flex aic mx-auto w-120p">
                                    <button
                                      class="flex aic jcc w-35p h-35p rounded-full bg-gray"
                                      @click="remove(product)"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M18 12H6"
                                        />
                                      </svg>
                                    </button>
                                    <div class="flex aic jcc text-primary text-center fs-14 w-40p">
                                      {{ product.quantity }}
                                    </div>
                                    <button
                                      class="flex aic jcc w-35p h-35p rounded-full bg-gray"
                                      @click="add(product)"
                                      :class="{'cursor-not-allowed': product.is_package != 0}"
                                      :disabled="product.is_package != 0"
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="h-4 w-4"
                                        fill="none"
                                        viewBox="0 0 24 24"
                                        stroke="currentColor"
                                      >
                                        <path
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          stroke-width="2"
                                          d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                        />
                                      </svg>
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li class="row-span-3 md:row-span-6 col-span-5 md:col-span-1 flex justify-end md:aic md:jcc self-start xs:self-center mt-4 pr-3 md:mt-0 md:pr-0">
                                <div class="fs-14 text-primary b whitespace-nowrap">
                                  {{ currency }} {{ product.totalPrice }}
                                </div>
                              </li>
                            </ul>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div class="flex flex-col mt-16 mb-5">
                    <span class="flex aic justify-center lg:justify-end tracking-wide fs-14 space-x-12"><span class="text-gray-400">{{$t("Total Price")}}</span><b>{{ currency }} {{ TotalPrice }}</b></span>
                  </div>
                  <div class="flex flex-col lg:flex-row aic jcb w-full mt-5">
                    <router-link
                      to="/"
                      class="hidden lg:flex a-primary subway_footlong py-3 aic space-x-4 order-2 lg:order-1 fs-16"
                      type="button"
                    >
                      <i class="fa fa-angle-left text-2xl"></i>
                      <span class="pt-1">{{$t("Back to Restaurant")}}</span>
                    </router-link>

                    <button
                      v-if="ExtraProducts && ExtraProducts.length"
                      class="flex lg:hidden btn-secondary w-full py-3 aic jcc space-x-4 order-2 lg:order-1 mt-5"
                      type="button"
                      @click="showExtra = true"
                    >
                      <span class="pt-1">{{$t("Add side product")}}</span>
                    </button>

                    <router-link
                      to="/checkout"
                      class="text-center btn-green px-3 h-50p w-full lg:w-3/12 order-1 lg:order-2 fcc"
                      type="button"
                    >
                      {{$t("Continue")}}
                    </router-link>
                  </div>
                </div>
              </div>
              <div
                v-if="ExtraProducts && ExtraProducts.length"
                class="hidden lg:flex flex-col aic fixed bottom-0 left-0 lg:relative w-full lg:w-4/12 lg:pl-8 mt-16 lg:mt-0 add-item"
              >
                <div class="flex jcc aic w-full bg-secondary p-4">
                  <h2 class="fs-16 text-primary-dark subway_footlong">{{$t("You can add the items blow")}}</h2>
                </div>
                <div class="flex flex-col w-full py-5">
                  <div class="flex flex-col jcb aic pb-8 w-full">
                    <div class="space-y-4 divide-y-2 divide-dashed divide-gray-100 w-full">
                      <div
                        class="flex aic jcb w-full py-3"
                        v-for="product in ExtraProducts"
                        :key="product.id"
                      >
                        <img
                          :src="
                          product.image
                            ? storageUrl + product.image
                            : '/dish.webp'
                        "
                          :alt="product.name"
                          class="w-80p h-80p object-cover"
                        />
                        <div class="flex flex-col jcc pl-3 space-y-2">
                          <h3 class="fs-13 w-full whitespace-wrap text-primary-dark">
                            {{ product.name }}
                          </h3>
                          <h2 class="fs-14 w-full whitespace-wrap text-black">
                            {{ product.description }}
                          </h2>
                          <div class="flex aic space-x-6">
                            <div class="flex aic w-120p">
                              <button
                                class="flex aic jcc w-35p h-35p rounded-full bg-gray"
                                @click="removeFromBasket(product)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M18 12H6"
                                  />
                                </svg>
                              </button>
                              <div class="flex aic jcc text-primary text-center fs-14 w-40p">
                                {{ product.quantity }}
                              </div>
                              <button
                                class="flex aic jcc w-35p h-35p rounded-full bg-gray"
                                @click="addToBasket(product)"
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  class="h-4 w-4"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                  stroke="currentColor"
                                >
                                  <path
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                    d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                                  />
                                </svg>
                              </button>
                            </div>
                          </div>
                        </div>
                        <span class="fs-14 text-primary ml-auto mt-auto subway_footlong"><span class="mr-1">{{ currency }}</span>{{ product.price }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <CartExtra
      @closeCard="showExtra = false"
      :showExtra="showExtra"
    ></CartExtra>
  </div>
</template>
<script>
import CartExtra from "../components/CartExtra.vue";

export default {
  components: { CartExtra },
  data() {
    return {
      showExtra: false,
      loadExtraProdut: false,
    };
  },
  computed: {
    storageUrl() {
      return process.env.VUE_APP_STORAGE_URL;
    },
    restaurantId() {
      return localStorage.getItem("restaurantId");
    },
    currency() {
      return this.$store.state.orderiomApiPackage.restaurant.restaurantInfo
        .currency;
    },
    ShoppingCart() {
      return this.$store.state.orderiomApiPackage.product.ShoppingCart;
    },
    TotalPrice() {
      return this.$store.state.orderiomApiPackage.product.TotalPrice || "0.00";
    },
    SubtotalPrice() {
      return (
        this.$store.state.orderiomApiPackage.product.SubtotalPrice || "0.00"
      );
    },
    counter() {
      let quantity = this.ShoppingCart.map((m) => {
        return m.quantity;
      });
      let count = quantity.reduce(function (a, b) {
        return a + b;
      }, 0);
      return count;
    },
    shippingPrice() {
      return (
        this.$store.state.orderiomApiPackage.product.shippingPrice || "0.00"
      );
    },
    totalWithDiscount() {
      return this.$store.state.orderiomApiPackage.product.totalWithDiscount;
    },
    ExtraCategories() {
      let categories = [
        ...this.$store.state.orderiomApiPackage.product.categories,
      ];
      let extraList = categories.filter((cat) => {
        return cat.extra == 1;
      });
      return extraList;
    },
    ExtraProducts() {
      return this.loadExtraProdut
        ? this.$store.state.orderiomApiPackage.product.products
        : [];
    },
  },
  methods: {
    addToBasket(product) {
      this.$store.dispatch("order/addToBasket", {
        productId: product.id,
        restaurantId: localStorage.getItem("restaurantId"),
        attributeItems: [],
      });
    },
    removeFromBasket(product) {
      this.$store.dispatch("order/removeFromBasket", {
        productId: product.id,
        restaurantId: localStorage.getItem("restaurantId"),
      });
    },
    goCheckOut() {
      if (this.$route.path != "/cart") this.$router.push("/cart");
      this.$emit("closeCard");
    },
    add(product) {
      this.$store.dispatch("order/addToBasket", {
        productId: product.RestaurantProductId,
        attributeItems: product.items.map((m) => {
          return m.id;
        }),
        restaurantId: this.restaurantId,
      });
    },
    deletFromBasket(product) {
      this.$store.dispatch("order/deleteFromBasket", {
        productId: product.RestaurantProductId,
        restaurantId: localStorage.getItem("restaurantId"),
      });
    },
    remove(product) {
      this.$store.dispatch("order/removeFromBasketWithAttr", {
        basketProductId: product.basketProductId,
        restaurantId: this.restaurantId,
      });
    },
  },
  mounted() {
    // this.$store
    //   .dispatch("product/getCategories", localStorage.getItem("restaurantId"))
    //   .then(() => {
    if (this.ExtraCategories.length) {
      this.$store
        .dispatch("product/getProducts", {
          category: this.ExtraCategories[0],
          restaurantId: localStorage.getItem("restaurantId"),
        })
        .then(() => {
          this.loadExtraProdut = true;
        });
    }
    //   });
  },
};
</script>
